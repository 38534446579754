/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import classnames from 'classnames'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import BlockManager from '../../BlockManager'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'
import { FormContext } from '../../../../_shared/components/Form'
import VA from '../../../../frontend/client/api/VA'
import Field, { FieldConfig, FieldData } from '../../Field'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import dateFnsFormat from 'date-fns/format'

export interface CalendlyProps {
  block: CalendlyField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

const rotate = keyframes`
  100%   {transform: rotate(360deg)}
`

const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`

const Loader = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 8px solid #000;
    animation: ${prixClipFix} 2s linear infinite;
  }
`

const CalendlyLoader = () => {
  return (
    <div style={{ margin: '4rem 0' }}>
      <Loader />
      <h2 style={{ textAlign: 'center', marginTop: '3rem' }}>Saving your appointment...</h2>
    </div>
  )
}

const Calendly = ({ block, className, context }: CalendlyProps) => {
  const { onSubmit, setValue, setFieldValue, formData, ...ree } = useContext(FormContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      if (block.data.config?.submitWhenBooked) {
        setIsLoading(true)

        const companyId = VA.getResponse().companyId
        const eventUrl = e.data?.payload?.event?.uri
        const inviteeUrl = e.data?.payload?.invitee?.uri

        if (companyId && eventUrl) {
          const response = await fetch(
            `${location.origin}/calendlyEvent?companyId=${companyId}&eventUrl=${eventUrl}&inviteeUrl=${inviteeUrl}`,
            {
              method: 'GET',
              mode: 'same-origin',
              cache: 'no-cache',
              headers: {
                'Content-Type': 'application/json', // eslint-disable-line
                Accept: 'application/json', // eslint-disable-line
                Authorization: 'Bearer ' + VA.token, // eslint-disable-line
              },
              credentials: 'same-origin',
            }
          )

          const data = await response.json()

          if (data.success) {
            const event = data.message.event
            const invitee = data.message.invitee
            const calendlyEvent = {
              name: event.name,
              createdAt: event.created_at,
              startTime: event.start_time,
              endTime: event.end_time,
              eventGuests: event.event_guests,
              eventUri: event.uri,
              questions: invitee.questions_and_answers,
            }
            setValue({ name: block.id }, calendlyEvent)
          }
        }
        onSubmit(null, {
          buttonId: null,
        })
      }
    },
  })

  const url = block.data.config?.url

  if (!url) {
    return (
      <div className={classnames(className, 'calendly-no-url')}>
        <p>Calendly URL is required</p>
      </div>
    )
  }

  if (isLoading) {
    return <CalendlyLoader />
  }

  return (
    <div className={className}>
      <InlineWidget
        url={block.data.config?.url}
        styles={{ height: block.data.config?.height + 'px' }}
        prefill={{
          email: context.response.getField('email')?.value as string,
          firstName: context.response.getField('firstName')?.value as string,
          lastName: context.response.getField('lastName')?.value as string,
          name: context.response.getField('name')?.value as string,
          location: context.response.getField('phone')?.value as string,
        }}
        utm={{
          utmCampaign: block.data.config?.utmCampaign || context.response.utm_campaign,
          utmContent: block.data.config?.utmContent || context.response.utm_content,
          utmMedium: block.data.config?.utmMedium || context.response.utm_medium,
          utmSource:
            block.data.config?.utmSource || context.response.utm_source || context.pathway.name,
          utmTerm: block.data.config?.utmTerm || context.response.utm_term,
        }}
      />
    </div>
  )
}

export const StyledCalendly = styled(Calendly)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};

  &.calendly-no-url {
    text-align: center;
    color: red;
  }
`

export interface CalendlyFieldConfig extends FieldConfig {
  height: number
  url: string
  submitWhenBooked?: boolean
  utmCampaign?: string
  utmContent?: string
  utmMedium?: string
  utmSource?: string
  utmTerm?: string
}

export interface CalendlyFieldData extends FieldData {
  config?: CalendlyFieldConfig
}

interface CalendlyEventGuests {
  email: string
  createdAt: string
  updatedAt: string
}

interface CalendlyEventQuestions {
  question: string
  answer: string
  position: number
}

export interface CalendlyEvent {
  name: string
  createdAt: string
  startTime: string
  endTime: string
  eventGuests: CalendlyEventGuests[]
  uri: string
  questions: CalendlyEventQuestions[]
}

export interface CalendlyFieldResponse extends FieldResponse {
  data: CalendlyEvent
}

export interface CalendlySimpleOutputProps {
  answer: CalendlyFieldResponse
}

const simpleOutput: React.FC<CalendlySimpleOutputProps> = ({
  answer,
}: CalendlySimpleOutputProps) => {
  return (
    <>
      <strong>Appointment</strong>
      <ul>
        <li>
          <strong>Name</strong>: {answer.data.name}
        </li>
        <li>
          <strong>Start Time</strong>:{' '}
          {dateFnsFormat(new Date(answer.data.startTime), 'MM/dd/yyyy h:mm a')}
        </li>
      </ul>
      {answer.data?.eventGuests?.length > 0 && (
        <>
          <strong>Guests</strong>
          <ul>
            {answer.data.eventGuests.map((guest, index) => (
              <li key={index}>{guest.email}</li>
            ))}
          </ul>
        </>
      )}
      {answer.data?.questions?.length > 0 && (
        <>
          <strong>Questions</strong>
          <ul>
            {answer.data?.questions.map((question) => (
              <li key={question.position}>
                <strong>{question.question}</strong>
                <br />
                {question.answer}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

const key = 'Calendly'
export default class CalendlyField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  declare data?: CalendlyFieldData
  static simpleOutput = simpleOutput
  static template: Partial<CalendlyField> = {
    data: {
      config: {
        label: 'Calendly',
        url: '',
        height: 1000,
        submitWhenBooked: true,
        utmCampaign: 'VirtualAdviser',
        utmContent: null,
        utmMedium: null,
        utmSource: null,
        utmTerm: null,
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<CalendlyField>) {
    super(params)
    Object.assign(this, CalendlyField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledCalendly
  }

  getFormSchema(context: FrontendContext): Record<string, unknown> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          createdAt: {
            type: 'string',
          },
          startTime: {
            type: 'string',
          },
          endTime: {
            type: 'string',
          },
          eventGuests: {
            type: 'array',
          },
          uri: {
            type: 'string',
          },
        },
      },
    }
  }

  /**
   * Converts the form value (usually a primitive) to a FieldResponse
   */
  formToDoc(value: CalendlyEvent, context?: FrontendContext): CalendlyFieldResponse {
    const event: CalendlyFieldResponse = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(context),
      value: dateFnsFormat(new Date(value.startTime), 'MM/dd/yyyy h:mm a'),
      type: (this.constructor as any).type,
      data: value,
    }

    return event
  }

  /**
   * Converts a FieldResponse to it's primitive equivalent
   */
  docToForm(doc: CalendlyFieldResponse): string {
    return doc.data.startTime
  }
}

BlockManager.registerBlockClass(CalendlyField)